<template>
    <Section>
      <Container>
        <div class="import-subview">
          <Table v-for="(sheet, index) in sheets" :key="index"
            isExportTable
            :showModalitiesNumber="false"
            :headers="sheet.headers"
            :staticHeaders="[]"
            :items="tableSheets[index]"
            :tools="false"
            simpleLayout
            class="table--trial-import"
          >
          <template v-for="(header, j) in sheet.headers" :key="j" v-slot:[header.slotName]>
            <span v-if="header.designation" v-html="header.designation"></span>
            <span v-else>Ne pas traiter</span>
            <template v-if="j>=1">
              <div class="table-col-overlay">
                <Btn
                  icon="create"
                  color="primary"
                  @click="openModal('selectVariable', {
                    coos: header.slotName,
                    variables: variables[getSheet(header.slotName)],
                  })"
                />
              </div>
            </template>
          </template>

          </Table>
        </div>
      </Container>
    </Section>

    <!-- Modals realizeEvent -->
    <Modal
      title="Selection de la variable à appliquer"
      :active="modal.selectVariable"
      :data="modalData"
      @modal-close="modal.selectVariable = false"
    >
      <template v-slot:modal-body="{ data }">
        <Radio v-for="(variable, index) in data.variables" :key="index"
          :toDisable="getDisabled(data.coos)??[]"
          :modelValue="selectedVariables[data.coos] ? selectedVariables[data.coos] : null"
          id="variables"
          :items="variable.variables"
          :label="variable.designation ? `${variable.designation} - ${variable.date_realisation}` : ''"
          :compkey="`${index}`"
          @update:modelValue="setVariables($event, data.coos)"
        />

      </template>
    </Modal>
  </template>

<script>
import Btn from '@/components/base/Btn.vue'
import Table from '@/components/table/Table.vue'
import Modal from '@/components/layout/Modal.vue'
import Radio from '@/components/form/Radio.vue'
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'

export default {
  name: 'EssaiImportDataAssignementView',

  components: {
    Modal,
    Table,
    Btn,
    Radio,
    Section,
    Container,
  },

  emits: ['updateEssais', 'set-step', 'update:modelValue', 'set-route'],

  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
    id: {
      type: [Number, String],
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tableSheets: [],
      sheets: [],
      variables: [],
      flatVariables: [],
      enabled: [],
      jeton: null,
      selectedVariables: {},
      step: 0,
      modalData: {},
      modal: {
        selectVariable: false,
      },
      componentKey: 0,
    }
  },

  watch: {
    modelValue() {
      if (this.modelValue) {
        this.analyse()
      }
      this.$emit('update:modelValue', false)
    },
  },

  mounted() {
    this.sheets = this.$store.getters['io/sheets']
    this.variables = this.$store.getters['io/variables']
    this.flatVariables = this.$store.getters['io/flatVariables']
    this.tableSheets = this.$store.getters['io/tableSheets']
    this.jeton = this.$store.getters['io/jeton']
    this.$emit('set-step', 0)
    this.enabled = this.$store.getters['io/enabled']
    this.setSelectedVariables(this.enabled)
  },

  methods: {
    setVariables(event, coos) {
      console.log(coos)
      console.log(this.sheets)
      this.selectedVariables[coos] = event
      const acoos = coos.split('_')
      this.$store.dispatch('io/setDisabled', { sheet: acoos[1], column: acoos[3], variable: event })
      this.sheets[acoos[1]].headers[acoos[3] - 4].designation = this.flatVariables[event]
      this.sheets[acoos[1]].headers[acoos[3] - 4].disabled = true
      if (this.flatVariables[event]) {
        this.sheets[acoos[1]].headers[acoos[3] - 4].disabled = false
      }
      this.modal.selectVariable = false
    },

    setSelectedVariables(enabled) {
      console.log(enabled)
      enabled.forEach((f, i) => {
        f.forEach((c, j) => {
          this.selectedVariables[`f_${i}_c_${j}`] = enabled[i][j] ? enabled[i][j] : null
          if (j - 2 > 0) {
            this.sheets[i].headers[j - 2].designation = this.flatVariables[enabled[i][j]] ?? 'Ne pas traiter'
            this.sheets[i].headers[j - 2].disabled = true
            if (this.flatVariables[enabled[i][j]]) {
              this.sheets[i].headers[j - 2].disabled = false
            }
          }
        })
      })
    },

    getSheet(coos) {
      const acoos = coos.split('_')
      return acoos[1]
    },

    getDisabled(coos) {
      const acoos = coos.split('_')
      const disabled = this.$store.getters['io/disabled']
      return disabled[acoos[1]][acoos[3]]
    },

    analyse() {
      const enabled = this.formatEnabled()
      const { jeton } = this
      let isEmpty = true

      enabled.forEach((sheet) => {
        if (isEmpty) {
          const atest = Object.values(sheet.variables).map((v) => v === null)
          isEmpty = !atest.includes(false)
        }
      })

      if (!isEmpty) {
        if (jeton) {
          this.fetchService.post(`essai/${this.$route.params.id}/evenement/fiche/notation/fichier/${jeton}/analyse`, enabled)
            .then((response) => {
              const datas = response.data

              this.$store.dispatch('io/setAnalysisResults', datas)
              this.$router.push({ name: 'essaiImportReport' })
            })
        }
      } else {
        this.emitter.emit('alert', {
          type: 'warning',
          content: 'Une variable au moins doit être assignée.',
        })
      }
    },

    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    formatEnabled() {
      const datas = []
      this.enabled.forEach((variables, index) => {
        const base = this.sheets[index].variables
        Object.keys(base).forEach((i) => {
          base[i] = variables[i] ? { id: parseInt(variables[i], 10) } : null
        })
        datas.push({
          variables: base,
        })
      })
      return datas
    },
  },
}
</script>

<style lang="scss" scoped>

.table--trial-import {
  ::v-deep(table) {
    border-radius: $border-radius-base;

    // Borders
    th,
    td {
      &:first-child {
        border-left: 1px solid $color-primary-lighter;
      }
    }

    tr:first-child {
      th {
        background-color: $color-primary-lightest !important;
        color: $color-primary-dark;
        border-color: $color-primary-lighter;

        &:first-child {
          position: initial;
          font-size: $font-size-big;
          text-transform: uppercase;
          background-color: $color-primary-dark;
          @include v-padding($gutter-half);
        }
      }
    }

    thead {
      th {
        &,
        &.table-head--odd {
          background-color: $color-primary;
          border-color: $color-primary-lighter !important;
        }
      }
      tr {
        &:first-child {
          th {
            position: relative;
            min-height: 14rem + $gutter-half;
            border-right-color: $color-primary-lighter !important;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:nth-last-child(2) {
            border-right-color: $color-primary-lighter !important;
          }
        }
        &:last-child {
          td {
            border-bottom-color: $color-primary-lighter;
          }
        }
      }
    }

    td.table-cell--empty-table {
      border-right-color: $color-primary-lighter !important;
    }

    .table-index {
      background-color: $color-primary-lighter;
    }
  }

  .table-col-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: right;
    align-items: center;
    padding: $gutter-quarter;
    opacity: 0;
    transition: all 0.25s;

    &:hover {
      opacity: 1;
    }
  }
}

.input-file--import {
  padding-right: $gutter-half;
  padding-left: 5.2rem + $gutter-half;
  @include v-padding($gutter-half);

  @include bp('sm') {
    display: flex;
    align-items: center;
    gap: $gutter;

    ::v-deep(label) {
      margin-bottom: 0;
      font-size: $font-size-big;
    }

    ::v-deep(.input) {
      flex-grow: 1;
      flex-direction: row;
      justify-content: flex-start;
      gap: $gutter-half;
    }

    ::v-deep(.input-file-filename) {
      margin-bottom: 0;
    }
    ::v-deep(.input-file-label) {
      margin-bottom: 0;
    }
  }

  @include bp($bp-expanded-container) {
    padding-right: $gutter;
    padding-left: 5.2rem + $gutter;
  }
}
.essai-statut {
  height: 15px;
  width: 15px;
  border: 1px solid $color-gray;
  border-radius: $border-radius-base;
  transform: rotate(45deg);

  &--filled {
    border: 1px solid $color-primary;
    background: $color-primary;
  }
}
</style>
